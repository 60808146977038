import React, { useEffect } from "react";
import "./App.css";
import {
  Box,
  Card,
  Loader,
  Page,
  WixDesignSystemProvider,
  Text,
  EmptyState,
  Button,
  Badge,
  BadgeSkin,
  Layout,
  Cell,
  FormField,
  Input,
  MarketingPageLayoutContent,
  TextButton,
  HorizontalTimeline,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Everything in Standard +",
  "Distribution on 500+ sites",
  "Seen on Fox affiliate",
  "SEO Optimized",
  "Permanent listing (when plan active)",
];

const INTERVAL = 4000;

function App() {
  const { t }= useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [citationStatus, setCitationStatus] = React.useState(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });
  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    setStatus();
    // if status is null/undefined, check again in every 5 seconds
    const checker = setInterval(() => {
      if (!citationStatus) {
        setStatus();
      } else {
        clearInterval(checker);
      }
    }, 5000);
    const url = new URLSearchParams(window.location.search).get("siteUrl");
    if (url) {
      setSiteUrl(url);
    }
    return () => {
      clearInterval(checker);
    };
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/seenon/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          setEmail(data.email);
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        if (data.instance?.isFree) {
          setIsUpgraded(false);
          setIsUpgradeBannerOpen(data.instance.isFree !== false);
        } else {
          setIsUpgraded(true);
        }
      })
      .catch(() => {});
  }

  function setStatus() {
    fetch(BASE_URL + "/status", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCitationStatus(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=8d864cca-7715-415d-8fa9-55206d15ae6d&redirectUrl=https://certifiedcode.wixsite.com/seenon/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const statuses: {
    [key: string]: {
      title: string;
      subtitle: string;
      skin: string;
    };
  } = {
    PENDING: {
      title: t('pending-title'),
      subtitle: t('pending-subtitle'),
      skin: "standard",
    },
    IN_PROGRESS: {
      title: t('progress-title'),
      subtitle: t('progress-subtitle'),
      skin: "warning",
    },
    ACTION_REQUIRED: {
      title: t('action-title'),
      subtitle: t('action-subtitle'),
      skin: "danger",
    },
    DONE: {
        title: t('done-title'),
        subtitle: t('done-subtitle'),
      skin: "success",
    },
  };

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"8d864cca-7715-415d-8fa9-55206d15ae6d"}?appInstanceId=${
    instanceData?.instance?.instanceId
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "${CONTENT[index]}"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={t('seenon-title')}
          actionsBar={
            <Box gap="SP2">
              <Button
                suffixIcon={<Icons.PremiumFilled />}
                skin="premium"
                priority="secondary"
                as="a"
                href={upgradeUrl}
                target="_blank"
              >
                {isUpgraded ? t('manage-plan') : t('pricing-button')}
              </Button>
              <Button
                skin="dark"
                onClick={() => {
                  window.Intercom("showNewMessage");
                }}
              >
                {t('contact-button')}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          {isLoading ? (
            <Loader text="Loading" />
          ) : !isUpgraded ? (
            <Box gap="SP3" direction="vertical">
              <EmptyState
                theme="page"
                title={t('build-title')}
                subtitle={t('build-subtitle')}
              >
                <Button
                  as="a"
                  href={`https://heyflow.id/seenon-onboarding?email=${email}&website=${siteUrl}&instanceId=${instanceData?.instance?.instanceId}`}
                  // target='_blank'
                  skin="ai"
                  suffixIcon={<Icons.AIFilled />}
                >
                  {t('build-started-button')}
                </Button>
              </EmptyState>
            </Box>
          ) : (
            <Layout>
              <Cell span={12}>
                {!isUpgraded && (
                  <Card>
                    <Card.Content>
                      <HorizontalTimeline
                        items={[
                          {
                            label: t('label-completed'),
                            line: "filled",
                            icon: <HorizontalTimeline.CompleteIcon />,
                          },
                          {
                            label: t('label-pending'),
                            line: "filled",
                            icon: <HorizontalTimeline.DestructiveIcon />,
                          },
                          {
                            label: t('label-working'),
                          },
                          {
                            label: t('label-live'),
                          },
                        ]}
                      />
                    </Card.Content>
                  </Card>
                )}
              </Cell>
              <Cell span={12}>
                <Card>
                  <Card.Header title={t('news-title')} />
                  <Card.Divider />
                  <Card.Content>
                    {!isUpgraded ? (
                      <EmptyState
                        title={t('status-title')}
                        subtitle={t('status-subtitle')}
                      >
                        <Button
                          prefixIcon={<Icons.PremiumFilled />}
                          skin="premium"
                          size="large"
                          as="a"
                          href={`https://www.wix.com/apps/upgrade/${"8d864cca-7715-415d-8fa9-55206d15ae6d"}?appInstanceId=${
                            instanceData?.instance?.instanceId
                          }`}
                          target="_blank"
                        >
                          {t('plan-button')}
                        </Button>
                      </EmptyState>
                    ) : (
                      <EmptyState
                        title={t('track-title')}
                        subtitle={t('track-subtitle')}
                      >
                        <Button
                          prefixIcon={<Icons.ChatFilled />}
                          skin="dark"
                          size="large"
                          onClick={() => {
                            window.Intercom(
                              "showNewMessage",
                              t('submission-status')
                            );
                          }}
                        >
                          {t('contact-button')}
                        </Button>
                      </EmptyState>
                    )}
                  </Card.Content>
                </Card>
              </Cell>
            </Layout>
          )}
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
